/*------------------------------------------------------------------
[Header]
Project:	Jampack
-------------------------------------------------------------------*/
/*Top Nav*/
.hk-wrapper {
	.hk-navbar {
		border-bottom: 1px solid $grey_light_4;
		height: 65px;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		-ms-flex-pack: space-between;
		.navbar-nav {
			.nav-item {
				.dropdown {
					.dropdown-menu {
						position: absolute;
					}
					&.dropdown-notifications {
						.dropdown-menu {
							width: 21.875rem;
							.dropdown-header {
								color: $black_2;
								display: -webkit-box;
								display: -ms-flexbox;
								display: flex;
								-webkit-box-align: center;
								-ms-flex-align: center;
								align-items: center;
								justify-content: space-between;
								-webkit-justify-content: space-between;
								-ms-flex-pack: space-between;
								border-bottom: 1px solid $grey_light_5;
							}
							.dropdown-body {
								height: 23.75rem;
							}
							.dropdown-footer {
								border-top: 1px solid $grey_light_5;
								padding: 1rem;
								display: -webkit-box;
								display: -ms-flexbox;
								display: flex;
								justify-content: center;
								-webkit-justify-content: center;
								-ms-flex-pack: center;
								color: $primary;
								font-size: 0.875rem;
							}
							.dropdown-item {
								white-space: inherit;
								padding-top: 0.75rem;
								padding-bottom: 0.75rem;
								border-radius: 0.5rem;
								.media {
									.media-head {
										margin-right: 1rem;
									}
									.media-body {
										> div {
											.notifications-info {
												display: -webkit-box;
												display: -ms-flexbox;
												display: flex;
												-webkit-box-align: center;
												-ms-flex-align: center;
												align-items: center;
												margin-top: 0.25rem;
												> .badge {
													margin-right: 0.625rem;
												}
												.notifications-time {
													font-size: 0.75rem;
													color: $grey_dark_3;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.navbar-search {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			.input-search {
				input.form-control {
					> span.btn-input-clear {
						display: none;
					}
					+ {
						.input-suffix,
						.input-prefix {
							// pointer-events: none;
							> span.spinner-border {
								display: none;
							}
						}
					}
					&:focus {
						+ {
							.input-suffix,
							.input-prefix {
								border: none;
								pointer-events: auto;
								> span:not(.btn-input-clear):not(.spinner-border) {
									display: none;
								}
							}
						}
					}
				}
				&.loading {
					input.form-control {
						&:focus {
							+ {
								.input-suffix,
								.input-prefix {
									> span {
										&.btn-input-clear {
											display: none !important;
										}
										&.spinner-border {
											display: inline;
										}
									}
								}
							}
						}
					}
				}
			}
			.dropdown-menu {
				width: 18rem;
				.dropdown-body {
					max-height: 19.75rem;
				}
				.dropdown-footer {
					border-top: 1px solid $grey_light_5;
					padding: 1rem;
					justify-content: center;
					-webkit-justify-content: center;
					-ms-flex-pack: center;
					color: $primary;
					font-size: 0.875rem;
				}
				.dropdown-item {
					white-space: inherit;
				}
			}
		}
		.nav-start-wrap,
		.nav-end-wrap {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			> .navbar-nav {
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
			}
		}
		.navbar-brand {
			.brand-img {
				&:last-child {
					margin-left: 0.5rem;
				}
			}
		}
	}
	&[data-navbar-style="collapsed"] {
		.hk-navbar {
			@include transform(translateY(-100%));
			overflow: hidden;
		}
		.hk-pg-wrapper {
			padding-top: 0;
		}
	}
}
@media (min-width: 992px) {
	.hk-wrapper {
		.hk-navbar {
			@include transition(0.2s ease);
		}
	}
}
@media (min-width: 1200px) {
	.hk-wrapper {
		.hk-navbar {
			.navbar-search {
				.input-search {
					input.form-control {
						width: 18rem;
						&:focus {
							+ {
								.input-suffix,
								.input-prefix {
									> span {
										&.btn-input-clear {
											display: inline !important;
										}
									}
								}
							}
						}
					}
				}
			}

			.nav-start-wrap,
			.nav-end-wrap {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				> .navbar-nav {
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
				}
			}
		}
		&[data-navbar-style="collapsed"] {
			&[data-layout="horizontal"] {
				.hk-pg-wrapper {
					padding-top: 65px;
				}
				&[data-layout-style="default"] .hk-menu {
					top: 0;
				}
			}
		}
	}
}
@media (max-width: 1199px) {
	.hk-wrapper {
		.hk-navbar .navbar-search {
			.mobile-search {
				.input-search {
					.input-suffix,
					.input-prefix {
						pointer-events: auto !important;
						> span {
							&.btn-input-clear {
								display: inline !important;
							}
						}
					}
				}
			}
			.dropdown-menu {
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				.dropdown-body {
					max-height: calc(100% - 55px);
				}
			}
		}
	}
}
